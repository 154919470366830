<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="main-body" v-else>
      <delete-modal
          :dialog="dialog"
          @close="closeModal"
          description="Are You sure you want to delete this Booking Admin"
          @deleteAction="deleteBookingAdmin"
          :doneLoading="deleteLoading"
      />

      <v-col sm="12" class=" tw-flex tw-justify-between tw-items-center tw-mt-10">
        <button
            class="tw-flex tw-items-center tw-mt-6 overview-btn"
            @click="backToAllAdmins"
        >
          <icons name="arrow-back" :color="companyColor" />
          <span class="tw-ml-4" :style="{ color: companyColor }"
          >All Booking Admins</span
          >
        </button>
        <div class="tw-flex tw-items-center" v-if="userData.userRole === 'transportCompanyManager'
        && staffData.terminalAssignedId === adminData.terminalAssignedId">
          <button @click="selectEditTab">
            <icons name="edit" :color="companyColor" />
          </button>
          <button class="tw-pl-5" @click="openDeleteModal">
            <icons name="trash" :color="companyColor" />
          </button>
        </div>
      </v-col>
      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16">
        <div class="lg:tw-px-12">
          <div class="admin-img-container">
            <img
                :src="adminData.profileImage"
                v-if="adminData.profileImage"
                :alt="adminData.firstname"
            />
          </div>
        </div>

        <v-row class="tw-mt-6 lg:tw-px-12 tw-justify-between tw-flex-col md:tw-flex-row">
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.firstname
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.lastname
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.email
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Mobile</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.phoneNumber
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.terminalAssigned
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.address.houseNumber
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.address.streetName
                }}</v-col>
            </div>
          </v-col>


          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Town/City</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.address.city
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">State</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.address.state
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  adminData.address.country
                }}</v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import {
  getBookingAdminById,
  deleteBookingAdmin,
} from "@/services/api/APIService";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
import Icons from "../../reuseables/Icons";
export default {
  name: "TerminalManagersingleBookingAdmin",
  components: {Icons, DeleteModal, Loader},
  data() {
    return {
      companyData: {},
      adminData: {},
      companyColor: "#004AAD",
      loading: false,
      dialog: false,
      deleteLoading: false,
      adminId: "",
      userData:{},
      staffData:{}
    };
  },
  methods: {
    openDeleteModal() {
      console.log("click me");
      this.dialog = true;
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.adminId = "";
    },
    backToAllAdmins() {
      this.$store.dispatch(
        "terminalManagerBookingAdminComponent/setCurrentComponent",
        "allBookingAdmins"
      );
      sessionStorage.setItem(
        "terminalManagerBookingAdminComponent",
        "allBookingAdmins"
      );
    },
    selectEditTab() {
      this.$store.dispatch(
          "terminalManagerBookingAdminComponent/setCurrentComponent",
          "editBookingAdmin"
      );
      sessionStorage.setItem("terminalManagerBookingAdminComponent", "editBookingAdmin");
      sessionStorage.setItem("adminId", this.adminData.id)
    },
    async deleteBookingAdmin() {
      this.deleteLoading = true;
      let data = {
        transportCompanyId: this.companyData.id,
        bookingAdminId: this.adminId,
      };
      try {
        const response = await deleteBookingAdmin(data);
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.deleteLoading = false;
        this.backToAllAdmins();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.deleteLoading = false;
        this.closeModal();

        console.log(error.response);
      }
    },
    async getAdminById() {
      let data = {
        transportCompanyId: this.companyData.id,
        bookingAdminId: this.adminId,
      };
      try {
        const response = await getBookingAdminById(data);

        this.adminData = await response.data;
        this.loading = false;
        console.log(response);
      } catch (error) {
        console.log(error.response);
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let bookingAdminId = sessionStorage.getItem("bookingAdminId");
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    if (bookingAdminId) {
      this.adminId = bookingAdminId;
    } else {
      return this.backToAllAdmins();
    }
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    if (userData){
      this.userData = userData
    }
    if (staffData){
      this.staffData = staffData
    }
    await this.getAdminById();
  },
};
</script>

<style lang="scss" scoped>
.admin-img-container {
  width: 200px;
  height: 220px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.label-key {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
