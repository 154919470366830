<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllBookingAdmins from "./AllBookingAdmin";
import SingleBookingAdmin from "./SingleBookingAdmin";
import AddBookingAdmin from "../../transportCompany/bookingAdmin/AddBookingAdmin";
import EditBookingAdmin from "../../transportCompany/bookingAdmin/EditBookingAdmin";
import { mapGetters } from "vuex";
export default {
  name: "ViewBookingAdminDashboardRoute",
  computed: {
    ...mapGetters("terminalManagerBookingAdminComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allBookingAdmins") {
        return AllBookingAdmins;
      } else if (component === "singleBookingAdmin") {
        return SingleBookingAdmin;
      }
      else if (component === "addBookingAdmin"){
        return AddBookingAdmin
      }
      else if (component === "editBookingAdmin"){
        return EditBookingAdmin
      }
    },
  },
  created() {
    let component = sessionStorage.getItem(
      "terminalManagerBookingAdminComponent"
    );
    if (!component) {
      sessionStorage.setItem(
        "terminalManagerBookingAdminComponent",
        "allBookingAdmins"
      );
      this.$store.dispatch(
        "terminalManagerBookingAdminComponent/setCurrentComponent",
        "allBookingAdmins"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerBookingAdminComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
